import { ADD_FIAT_TRANSACTION, GET_FIAT_TRANSACTION_LIST } from '../../types/types'

const initialState = {
  forceUpdate: false,
  transactions: []
}
/*{
  "id": "1",
  "amount": "50,000",
  "paymentMethod": "GPAY",
  "transactionID": "13286486384763874",
  "timeStamp": "Today, 03:12 PM",
  "status": "Success",
  "type": "deposit"
}*/

export const fiatWalletReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_FIAT_TRANSACTION_LIST:
      return {
        ...state,
        transactions: payload
      }
    case ADD_FIAT_TRANSACTION:
      state.transactions.unshift(payload)
      return state
    default:
      return state
  }
}
