import {
  ADD_USER_EMAIL,
  ADD_USER_AVATAR,
  ADD_USER_USERNAME,
  ADD_USER_PHONE,
  ADD_USER_FULLNAME,
  ADD_USER_ADDRESS,
  ADD_USER_AADHARNUM,
  ADD_USER_FRONTIMAGE,
  ADD_USER_BACKIMAGE,
  ADD_USER_ACCOUNT_NAME,
  ADD_USER_ACCOUNT_NUMBER,
  ADD_USER_CONFIRM_ACCOUNT_NUMBER, ADD_USER_IFSCCODE, ADD_USER,
  ADD_USER_UPDATE_FIAT_BALANCE, ADD_USER_ISLOADING,
  ADD_USER_CRYPTO_ADDRESSES
} from '../../types/types'

const initialState = {
  loading: false,
  email: '',
  avatar: '',
  username: '',
  userphone: '',
  fullname: '',
  address: '',
  aadharnum: '',
  frontimage: '',
  backimage: '',
  accountName: '',
  accountNumber: '',
  confirmAccountNumber: '',
  IFSCCode: '',
  KYCVerified: false,
  cryptoAddresses: {
    TRC20: []
  },
  fiatBalance: '00.00',
  cryptoBalance: {
    // BTC: '00.00',
    USDT: '00.00'
  }
}

export const userReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case ADD_USER:
      return payload
    case ADD_USER_EMAIL:
      return {
        ...state,
        email: payload
      }
    case ADD_USER_AVATAR:
      return {
        ...state,
        avatar: payload
      }
    case ADD_USER_USERNAME:
      return {
        ...state,
        username: payload
      }
    case ADD_USER_PHONE:
      return {
        ...state,
        userphone: payload
      }
    case ADD_USER_FULLNAME:
      return {
        ...state,
        fullname: payload
      }
    case ADD_USER_ADDRESS:
      return {
        ...state,
        address: payload
      }
    case ADD_USER_AADHARNUM:
      return {
        ...state,
        aadharnum: payload
      }
    case ADD_USER_FRONTIMAGE:
      return {
        ...state,
        frontimage: payload
      }
    case ADD_USER_BACKIMAGE:
      return {
        ...state,
        backimage: payload
      }
    case ADD_USER_ACCOUNT_NAME:
      return {
        ...state,
        accountName: payload
      }
    case ADD_USER_ACCOUNT_NUMBER:
      return {
        ...state,
        accountNumber: payload
      }
    case ADD_USER_CONFIRM_ACCOUNT_NUMBER:
      return {
        ...state,
        confirmAccountNumber: payload
      }
    case ADD_USER_CRYPTO_ADDRESSES: {
      return {
        ...state,
        cryptoAddresses: payload
      }
    }
    case ADD_USER_IFSCCODE:
      return {
        ...state,
        IFSCCode: payload
      }
    case ADD_USER_UPDATE_FIAT_BALANCE:
      return {
        ...state,
        fiatBalance: payload
      }
    case ADD_USER_ISLOADING:
      return {
        ...state,
        loading: payload
      }
    default:
      return state
  }
}