// React
import React, { createContext, Suspense, useState } from "react";

// Material UI
import Box from "@mui/system/Box";
import CustomTheme from "./Theme/CustomTheme";
import { ThemeProvider } from "@mui/material/styles";

// Router
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

// Styles
import "./App.css";

// Progress Loader
import ProgressLoader from "./components/ProgressLoader/ProgressLoader";

// Auth Provider
import AuthProvider from "./contexts/AuthProvider";
import { ModalSkeletons } from "./components/Skeletons/ComponentSkeletons";

// Redux store provider
import { Provider } from 'react-redux'
import store from './store/store'

/**********************************
          Lazy Pages
***********************************/

// Registration
const Registration = React.lazy(() =>
  import("./Pages/Registration/Registration")
);
const SignUpInterface = React.lazy(() =>
  import("./Pages/Registration/SignUpInterface/SignUpInterface")
);
const ConfirmSignUp = React.lazy(() =>
  import("./Pages/Registration/SignUpInterface/ConfirmSignUp")
);
const TwoFAPage = React.lazy(() =>
  import("./Pages/Registration/TwoFAPage/TwoFAPage")
);
const TwoFAPin = React.lazy(() =>
  import("./Pages/Registration/TwoFAPage/TwoFAPin")
);

// Login
const Login = React.lazy(() => import("./Pages/Login/Login"));
const SignInInterface = React.lazy(() =>
  import("./Pages/Login/SignInInterface/SignInInterface")
);
const ForgotPass = React.lazy(() =>
  import("./Pages/Login/ForgotPass/ForgotPass")
);
const ResetPass = React.lazy(() => import("./Pages/Login/ResetPass/ResetPass"));

// Account Setup
const AccountSetup = React.lazy(() =>
  import("./Pages/AccountSetup/AccountSetup")
);
const OTPVerification = React.lazy(() =>
  import("./Pages/Login/OTPVerification/OTPVerification")
);

// Private Route
const PrivateRoute = React.lazy(() => import("./Private/PrivateRoute"));

// Wallets
const Wallets = React.lazy(() => import("./Pages/Wallets/Wallets"));
const CryptoWallet = React.lazy(() =>
  import("./Pages/CryptoWallet/CryptoWalletInterface")
);
// const FiatWallet = React.lazy(() =>
//   import("./Pages/FiatWallet/FiatWalletInterface")
// );
const FiatWalletCardDeposit = React.lazy(() =>
  import("./Pages/FiatWallet/FiatWalletCardDeposit")
);

// Top Up
const TopUpPage = React.lazy(() => import("./Pages/TopUpPage/TopUpPage"));

// Coin details
// const CoinDetails = React.lazy(() => import("./Pages/CoinDetails/CoinDetails"));

// Profile Page
const ProfileInterface = React.lazy(() =>
  import("./Pages/ProfilePage/ProfileInteface")
);

// Static Page
const StaticPageInterface = React.lazy(() =>
  import("./Pages/StaticPages/StaticPageInterface")
);
const TermsAndCondition = React.lazy(() =>
  import("./Pages/StaticPages/TermsAndCondition")
);
const PrivacyPolicy = React.lazy(() =>
  import("./Pages/StaticPages/PrivacyPolicy")
);
const About = React.lazy(() => import("./Pages/StaticPages/About"));
const FAQ = React.lazy(() => import("./Pages/StaticPages/FAQ"));

// Onboarding
// const OnboardingPage = React.lazy(() =>
//   import("./Pages/Onboarding/OnboardingPage.js")
// );
//

// Color Context
export const ColorModeContext = createContext({ toggleColorMode: () => {} });

function App() {
  const { theme, colorMode } = CustomTheme();

  const colorModeTheme = localStorage.getItem("colorMode");
  if (!colorModeTheme) {
    if (theme.palette.mode === "dark") {
      localStorage.setItem("colorMode", "dark");
    } else {
      localStorage.setItem("colorMode", "dark");
    }
  } else if (colorModeTheme) {
    if (theme.palette.mode === "dark") {
      localStorage.setItem("colorMode", "dark");
    } else {
      localStorage.setItem("colorMode", "dark");
    }
  }

  return (
    <AuthProvider>
      <Provider store={store}>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <Box bgcolor={theme.palette.background.default} className="App">
              <div className="container">
                <BrowserRouter>
                  <Routes>
                    <Route
                      path="/"
                      element={<Navigate replace to="/wallets/buy-crypto" />}
                    />
                    {/*<Route*/}
                    {/*  path="/onboarding"*/}
                    {/*  element={*/}
                    {/*    <Suspense fallback={<ProgressLoader />}>*/}
                    {/*      <OnboardingPage />*/}
                    {/*    </Suspense>*/}
                    {/*  }*/}
                    {/*/>*/}
                    {/* Registration Page */}
                    <Route
                      path="/registration"
                      element={
                        <Suspense fallback={<ProgressLoader />}>
                          <Registration />
                        </Suspense>
                      }
                    >
                      {/* Sign Up Page */}
                      <Route
                        path="sign-up"
                        element={
                          <Suspense fallback={<ProgressLoader />}>
                            <SignUpInterface />
                          </Suspense>
                        }
                      />
                      <Route
                        path="confirm-sign-up"
                        element={
                          <Suspense fallback={<ProgressLoader />}>
                            <ConfirmSignUp />
                          </Suspense>
                        }
                      />
                      {/* TwoFa Page */}
                      <Route
                        path="two-factor-auth"
                        element={
                          <Suspense fallback={<ProgressLoader />}>
                            <TwoFAPage />
                          </Suspense>
                        }
                      />
                      {/* TwoFa Pin */}
                      <Route
                        path="two-factor-pin"
                        element={
                          <Suspense fallback={<ProgressLoader />}>
                            <TwoFAPin />
                          </Suspense>
                        }
                      />
                      {/* Login Page */}
                    </Route>
                    <Route
                      path="/login"
                      element={
                        <Suspense fallback={<ProgressLoader />}>
                          <Login />
                        </Suspense>
                      }
                    >
                      {/* Sign In Page */}
                      <Route
                        path="sign-in"
                        element={
                          <Suspense fallback={<ProgressLoader />}>
                            <SignInInterface />
                          </Suspense>
                        }
                      />
                      {/* Forgot Pass Page */}
                      <Route
                        path="forgot-pass"
                        element={
                          <Suspense fallback={<ProgressLoader />}>
                            <ForgotPass />
                          </Suspense>
                        }
                      />
                      {/* Reset Pass Page */}
                      <Route
                        path="reset-pass"
                        element={
                          <Suspense fallback={<ProgressLoader />}>
                            <ResetPass />
                          </Suspense>
                        }
                      />
                      {/* OTP Verification Page */}
                      <Route
                        path="otp-verification"
                        element={
                          <Suspense fallback={<ProgressLoader />}>
                            <OTPVerification />
                          </Suspense>
                        }
                      />
                    </Route>
                    {/* Account Setup Page */}
                    <Route
                      path="/account-setup"
                      element={
                        <Suspense fallback={<ProgressLoader />}>
                          <PrivateRoute>
                            <AccountSetup />
                          </PrivateRoute>
                        </Suspense>
                      }
                    />
                    {/* Wallets */}
                    <Route
                      path="/wallets"
                      element={
                        <Suspense fallback={<ProgressLoader />}>
                          <PrivateRoute>
                            <Wallets />
                          </PrivateRoute>
                        </Suspense>
                      }
                    >
                      {/*no need this for now*/}
                      {/*<Route*/}
                      {/*  path="/wallets/:coinName"*/}
                      {/*  element={*/}
                      {/*    <Suspense fallback={<ProgressLoader />}>*/}
                      {/*      <PrivateRoute>*/}
                      {/*        <CoinDetails />*/}
                      {/*      </PrivateRoute>*/}
                      {/*    </Suspense>*/}
                      {/*  }*/}
                      {/*/>*/}
                      <Route
                        path="buy-crypto"
                        element={
                          <Suspense fallback={<ProgressLoader />}>
                            <CryptoWallet />
                          </Suspense>
                        }
                      />
                      {/* Top Up */}
                      <Route
                        path="top-up"
                        element={
                          <Suspense fallback={<ProgressLoader />}>
                            <TopUpPage />
                          </Suspense>
                        }
                      />
                      {/* Fiat Wallet */}
                      {/*<Route*/}
                      {/*  path="fiat-wallet"*/}
                      {/*  element={*/}
                      {/*    <Suspense fallback={<ProgressLoader />}>*/}
                      {/*      <FiatWallet />*/}
                      {/*    </Suspense>*/}
                      {/*  }*/}
                      {/*/>*/}
                      <Route
                        path="card-deposit"
                        element={
                          <Suspense fallback={<ProgressLoader />}>
                            <FiatWalletCardDeposit />
                          </Suspense>
                        }
                      />
                    </Route>
                    <Route
                      path="/account"
                      element={
                        <Suspense fallback={<ProgressLoader />}>
                          <PrivateRoute>
                            <ProfileInterface />
                          </PrivateRoute>
                        </Suspense>
                      }
                    ></Route>
                    {/* Static Pages */}
                    <Route
                      path="/info"
                      element={
                        <Suspense fallback={<ProgressLoader />}>
                          <StaticPageInterface />
                        </Suspense>
                      }
                    >
                      {/* Terms and Condition */}
                      <Route
                        path="terms-and-conditions"
                        element={
                          <Suspense fallback={<ProgressLoader />}>
                            <TermsAndCondition />
                          </Suspense>
                        }
                      />
                      {/* Terms and Condition */}
                      <Route
                        path="privacy-policy"
                        element={
                          <Suspense fallback={<ProgressLoader />}>
                            <PrivacyPolicy />
                          </Suspense>
                        }
                      />
                      {/* About */}
                      <Route
                        path="contacts"
                        element={
                          <Suspense fallback={<ProgressLoader />}>
                            <About />
                          </Suspense>
                        }
                      />
                      {/* FAQ */}
                      <Route
                        path="FAQ"
                        element={
                          <Suspense fallback={<ProgressLoader />}>
                            <FAQ />
                          </Suspense>
                        }
                      />
                    </Route>
                  </Routes>
                </BrowserRouter>
              </div>
            </Box>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </Provider>
    </AuthProvider>
  );
}

export default App;
