import { ADD_CRYPTO_TRANSACTION, GET_CRYPTO_TRANSACTION_LIST } from '../../types/types'

const initialState = {
  transactions: []
}
/*{
  address: "WALLET"
  amount: "199"
  createdAt: "2022-03-23T13:16:57.171Z"
  cryptoAmount: "199"
  cryptoCurrency: "USDT"
  currency: "USD"
  id: "c1b58c15-a2f6-4dce-b37d-083f2061c128"
  owner: "email"
  rate: "1.00"
  type: "deposit"
  updatedAt: "2022-03-23T13:16:57.171Z"
}*/

export const cryptoWalletReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case GET_CRYPTO_TRANSACTION_LIST:
      return {
        ...state,
        transactions: payload
      }
    case ADD_CRYPTO_TRANSACTION:
      state.transactions.unshift(payload)
      return state
    default:
      return state
  }
}
