// React
import { useEffect, useState } from "react";
import { Auth } from 'aws-amplify'

const useFirebase = () => {
  // States
  const [user, setUser] = useState({});
  const [authError, setAuthError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPin, setShowPin] = useState(false);

  useEffect(()=> {
    const isUser = async () => {
      const { username } =  await Auth.currentAuthenticatedUser()
      if(username) {
        setUser({ email: username })
      }
    }
    isUser().then().catch(err => console.log(err))
  }, [setUser])

  // Sign Up
  const registerUser = async (email, password, navigate) => {
    setIsLoading(true); //It will holds the loading state

    // Registering the user
    Auth.signUp({
      username: email,
      password,
      attributes: {
        email,
      }
    }).then(({ user }) => {
        // setting auth error default false
        setAuthError("");
        // setup new user
        const newUser = { email };
        setUser(newUser);
        navigate("/registration/confirm-sign-up")
        // navigate("/account-setup"); //navigate to account setup route
      })
      .catch((err) => {
        console.log(err.message);
        setAuthError(err.message);
      })
      .finally(() => setIsLoading(false));
  };

  // confirm sign up
  const confirmSignUp = (code, navigate) => {
    setIsLoading(true);
    Auth.confirmSignUp(user.email, code)
      .then((data) => {
        console.log(data)
        navigate("/login/sign-in?step=/account-setup")
      })
      .catch((err) => {
        console.log(err)
        setAuthError(err.message)
      })
      .finally(() => setIsLoading(false))
  }

  // Sign In
  const logInUser = (email, password, location, navigate) => {
    // it will hold the loading state
    setIsLoading(true);
    setShowPin(false);
    // Signing In the user
    Auth.signIn(email, password).then((user) => {
      const prevDestination = location?.search?.split('=')[1] || location?.state?.from.pathname || "/";

        navigate(prevDestination); //It will navigate to homepage or the destination that the user came from
        setAuthError("");
        setUser({ email: user.username })
      })
      .catch((err) => {
        console.log(err.message);
        setAuthError(err.message);
      })
      .finally(() => {
        setIsLoading(false);
      }); //
  };

  // Using these will hold the user even if the page is reloaded or refreshed
  // useEffect(() => {
  //   Auth.currentAuthenticatedUser().then(({ username })=>{
  //     if (username) {
  //       setUser(username);
  //     } else {
  //       setUser({});
  //     }
  //     setIsLoading(false);
  //   })
  // }, []);

  // SignOut
  const logOut = (navigate) => {
    setIsLoading(true);
    Auth.signOut()
      .then(() => {
        setUser({})
        navigate('/login/sign-in')
      })
      .catch((err) => console.log(err.message))
      .finally(()=> setIsLoading(false))
  };

  return {
    user,
    setUser,
    authError,
    isLoading,
    registerUser,
    confirmSignUp,
    logInUser,
    logOut,
    showPin,
    setShowPin,
  };
};

export default useFirebase;
