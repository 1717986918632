import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import OnboardingPage from "./Pages/Onboarding/OnboardingPage";
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);
Auth.configure(awsconfig)

// let isFirstTime = false;
// const firstTimer = localStorage.getItem("firstTimer");

let isMobile = false;
if (window.innerWidth <= 550) {
  isMobile = true;
}
//
// if (!firstTimer && isMobile) {
//   localStorage.setItem("firstTimer", "true");
//   isFirstTime = true;
// } else if (firstTimer === "false") {
//   isFirstTime = false;
//   localStorage.setItem("firsTimer", "false");
// }

ReactDOM.render(
  <React.StrictMode>
    {/*{isFirstTime && isMobile ? <OnboardingPage /> : <App />}*/}
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
