import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { userReducer } from './reducers/userReducer'
import { fiatWalletReducer } from './reducers/fiatWalletReducer'
import { cryptoWalletReducer } from './reducers/cryptoWalletReducer'

const reducer = combineReducers({
  user: userReducer,
  fiatWallet: fiatWalletReducer,
  cryptoWallet: cryptoWalletReducer
})

const middleware = [thunk]

const store = createStore(reducer, {}, composeWithDevTools(applyMiddleware(...middleware)))

export default store