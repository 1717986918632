export const ADD_USER = 'ADD_USER'
export const ADD_USER_EMAIL = 'ADD_USER_EMAIL'
export const ADD_USER_AVATAR = 'ADD_USER_AVATAR'
export const ADD_USER_USERNAME = 'ADD_USER_USERNAME'
export const ADD_USER_PHONE = 'ADD_USER_PHONE'
export const ADD_USER_FULLNAME = 'ADD_USER_FULLNAME'
export const ADD_USER_ADDRESS = 'ADD_USER_ADDRESS'
export const ADD_USER_AADHARNUM = 'ADD_USER_AADHARNUM'
export const ADD_USER_FRONTIMAGE = 'ADD_USER_FRONTIMAGE'
export const ADD_USER_BACKIMAGE = 'ADD_USER_BACKIMAGE'
export const ADD_USER_ACCOUNT_NAME = 'ADD_USER_ACCOUNT_NAME'
export const ADD_USER_ACCOUNT_NUMBER = 'ADD_USER_ACCOUNT_NUMBER'
export const ADD_USER_CONFIRM_ACCOUNT_NUMBER = 'ADD_USER_CONFIRM_ACCOUNT_NUMBER'
export const ADD_USER_IFSCCODE = 'ADD_USER_IFSCCODE'
export const ADD_USER_UPDATE_FIAT_BALANCE = 'ADD_USER_UPDATE_FIAT_BALANCE'
export const ADD_USER_UPDATE_CRYPTO_BALANCE = 'ADD_USER_UPDATE_CRYPTO_BALANCE'
export const ADD_USER_CRYPTO_ADDRESSES = 'ADD_USER_CRYPTO_ADDRESSES'
export const ADD_USER_ISLOADING = 'ADD_USER_ISLOADING'

export const GET_FIAT_TRANSACTION_LIST = 'GET_FIAT_TRANSACTION_LIST'
export const ADD_FIAT_TRANSACTION = 'ADD_FIAT_TRANSACTION'

export const ADD_CRYPTO_TRANSACTION = 'ADD_CRYPTO_TRANSACTION'
export const GET_CRYPTO_TRANSACTION_LIST = 'GET_CRYPTO_TRANSACTION_LIST'

